// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.bg-cp-light {
    background-color: $cp-light;
}

.btn-pill {
    border-radius: 6.1875rem;
}

.btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .75rem 1.75rem;
    line-height: 1.6;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.875rem;
        padding-left: 1.875rem;
    }
}


body {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.6;
    color: $cp-blue;
}

.bg-blpl {
    background: rgb(53,176,234);
    background: linear-gradient(90deg, rgba(53,176,234,1) 0%, rgba(116,43,200,1) 100%) !important;
    color: #fff;
    font-weight: 600;
    box-shadow: 0px 0px 70px -6px rgba(53,176,234,0.75);
}

.bg-lgdg {
    background: rgb(46,150,152);
    background: linear-gradient(90deg, rgba(46,150,152,1) 0%, rgba(21,233,142,1) 100%) !important;
    color: #fff;
    font-weight: 600;
    box-shadow: 0px 0px 70px -6px rgba(46,150,152,0.75);
}

.bg-lpdp {
    background: rgb(127,38,193);
    background: linear-gradient(90deg, rgba(127,38,193,1) 0%, rgba(178,28,154,1) 100%) !important;
    color: #fff;
    font-weight: 600;
    box-shadow: 0px 0px 70px -6px rgba(127,38,193,0.75);
}

.bg-orpl {
    background: rgb(235,129,43);
    background: linear-gradient(90deg, rgba(235,129,43,1) 0%, rgba(127,38,193,1) 100%) !important;
    color: #fff;
    font-weight: 600;
    box-shadow: 0px 0px 70px -6px rgba(53,176,234,0.75);
}